<template>
  <div
    v-if="showData"
    class="h-100"
  >
    <template v-for="(section, index) in sections">
      <component
        :is="index === 0 ? `LayoutSectionFull` : `LayoutSection`"
        :key="index"
        :section-index="index"
        :bg-img="showData.bg_image"
      >
        <component
          :is="section.layout"
          :show-loading="showLoading"
          :show-data="showData"
          :content="section.content"
          @on-submit="onSubmit"
        />
      </component>
    </template>

    <app-footer />

    <div
      ref="ecpay"
      class="d-none"
    />
  </div>
  <!-- <component
    :is="layout"
    :show-loading="showLoading"
    :show-data="showData"
    @on-submit="onSubmit"
  >
    <router-view />
    <div
      ref="ecpay"
      class="d-none"
    />
  </component> -->
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'

import LayoutSectionFull from '@/layouts/components/sections/LayoutSectionFull.vue'
import LayoutSection from '@/layouts/components/sections/LayoutSection.vue'
import AppFooter from '@/layouts/components/sections/AppFooter.vue'

import dataStoreModule from './donateStoreModule'

// Banner頁
const AppBanner = () => import('@/layouts/components/sections/AppBanner.vue')

// 內頁
const DefaultPage = () => import('@/layouts/components/DefaultPage.vue')
const DefaultPageV2 = () => import('@/layouts/components/DefaultPageV2.vue')
const DefaultPageV4 = () => import('@/layouts/components/DefaultPageV4.vue')
const DefaultPageAll = () => import('@/layouts/components/DefaultPageAll.vue')

export default {
  components: {
    LayoutSectionFull,
    LayoutSection,
    AppFooter,

    //
    AppBanner,

    // Layouts
    DefaultPage,
    DefaultPageV2,
    DefaultPageV4,
    DefaultPageAll,
  },
  props: {
    donatePage: {
      type: String,
      default: 'DefaultPageV4',
    },
  },
  setup(props, { root, refs }) {
    const APP_STORE_MODULE_NAME = 'maple-donate'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, dataStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // loading 動畫
    const showLoading = ref(false)

    // const layout = computed(() => 'DefaultPage')
    const showData = ref(null)
    const layout = ref(null)
    const layoutAddOn = ref([])
    const sections = ref([
      // { layout: 'AppBanner' },
      { layout: props.donatePage },
      // { layout: 'AppContent' },
    ])

    // 讀取頁面初始設定
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/fetchDonatePage`, {
        slug: root.$route.params.slug,
      })
      .then(response => {
        layout.value = response.data.layout || props.donatePage
        showData.value = response.data
        const {
          name, banner, layoutB, layoutC,
        } = response.data

        if (banner.layout) {
          layoutAddOn.value.push({ layout: 'AppBanner', content: banner })
        }

        if (layoutB) {
          layoutAddOn.value.push({ layout: 'AppBanner' })
        }

        if (layoutC) {
          layoutAddOn.value.push({ layout: 'AppContent' })
        }
        // console.log(layoutAddOn.value)
        // console.log(layoutAddOn.value.map(m => m))

        sections.value = [
          ...layoutAddOn.value,
          { layout: props.donatePage },
        ]

        if (name) {
          document.title = `${name} - 贊助系統`
        }
      })
      .catch(error => {
        const { status } = error.response
        if (status === 404) {
          root.$router.replace({ name: 'error-404' })
        }
      })

    // 確定贊助
    const onSubmit = data => {
      showLoading.value = true

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/addPay`, {
          ...data,
          slug: root.$route.params.slug,
        })
        .then(response => {
          const { formSubmit, redirectUrl } = response.data
          if (redirectUrl) {
            window.location.replace(redirectUrl)
          }
          if (formSubmit) {
            root.$set(refs.ecpay, 'innerHTML', formSubmit)
            const formIds = ['#__ecpayForm', '#newebpay', '#payware', '#gomypay', '#pay', '#paysubmit']
            formIds.forEach(formId => {
              const query = document.querySelector(formId)
              if (query) {
                query.submit()
              }
            })
          }
        })
        .catch(error => {
          const { status } = error.response
          const { message } = error.response.data
          if (status === 400) {
            root.$swal.fire({
              title: '錯誤',
              html: message,
              icon: 'error',
            })
          }
          if (status === 433) {
            const messages = Object.values(message).map(m => m[0])
            root.$swal.fire({
              title: '錯誤',
              html: messages.join('<br>'),
              icon: 'error',
            })
          }

          // loading 結束
          showLoading.value = false
        })
    }

    return {
      showLoading,
      layout,
      layoutAddOn,
      sections,
      showData,

      onSubmit,
    }
  },
}
</script>

<style>
</style>
