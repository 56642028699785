<template>
  <section
    :style="`background-color: ${sectionIndex % 2 === 0 ? bgColor1 : bgColor2};`"
    class="py-5"
  >
    <slot />
  </section>
</template>

<script>
export default {
  components: {
  },
  props: {
    sectionIndex: {
      type: Number,
      required: true,
    },
    bgColor1: {
      type: String,
      default: () => '#222522',
    },
    bgColor2: {
      type: String,
      default: () => '#1b1d1b',
    },
  },
  setup() {
  },
}
</script>
