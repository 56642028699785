<template>
  <section
    style="bottom: 0; z-index: 9999;"
    class="position-fixed p-1 w-100"
  >
    <p class="mb-0 text-center">
      2021 ©
      <!-- href="https://1.envato.market/nk-portfolio" -->
      <!-- <a
        href="https://lin.ee/NiMospn"
        target="_blank"
      >
        Chillbot
      </a> -->
      <a href="#">
        Chillbot
      </a>
      . All rights reserved
    </p>
  </section>
</template>

<script>
// import {
//   BContainer,
// } from 'bootstrap-vue'
// import { computed } from '@vue/composition-api'
// import { useWindowSize } from '@vueuse/core'

export default {
  // components: {
  //   BContainer,
  // },
  // setup() {
  //   const { width, height } = useWindowSize()
  //   const windowSize = computed(() => ({ width: width.value, height: height.value }))

  //   return {
  //     windowSize,
  //   }
  // },
}
</script>

<style>
</style>
