<template>
  <!-- banner -->
  <section class="d-flex align-items-center position-relative w-100 h-100">
    <b-container
      fluid
      class="position-absolute"
    >
      <slot />
    </b-container>

    <b-img
      :src="bgImgComputed"
      :style="{ width: `${windowSize.width}px`, height: `${windowSize.height}px` }"
      style="object-fit: cover; object-position: 50% 50%; z-index: -1;"
    />
  </section>
</template>

<script>
import {
  BContainer, BImg,
} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'
import { useWindowSize } from '@vueuse/core'

export default {
  components: {
    BContainer,
    BImg,
  },
  props: {
    bgImg: {
      type: [String, null],
      default: () => null,
    },
  },
  setup(props) {
      
    const { width, height } = useWindowSize()

    const windowSize = computed(() => ({ width: width.value, height: height.value }))

    /* eslint-disable global-require */
    const bgImgComputed = computed(() => props.bgImg || require('@/assets/images/background/particles_bg.jpg'))

    return {
      windowSize,
      bgImgComputed,
    }
  },
}
</script>
