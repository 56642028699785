import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDonatePage(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/frontend/donate-page', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addPay(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/frontend/donate-pay', itemData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
