import Vue from 'vue'

// axios
import axios from 'axios'

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

// axiosIns.interceptors.response.use(
//   response => response,
//   error => {
//     // axios 的 data
//     const { status } = error.response
//     const { message } = error.response.data
//     if (message) {
//       if (status === 433) {
//         const messages = Object.values(message).map(m => m[0])
//         Vue.$toast({
//           component: ToastificationContent,
//           props: {
//             title: '操作過程發生錯誤',
//             messages,
//             icon: 'AlertTriangleIcon',
//             variant: 'danger',
//           },
//         })
//       } else {
//         Vue.$toast({
//           component: ToastificationContent,
//           props: {
//             title: message,
//             icon: (status === 401 && 'BellIcon') || 'AlertTriangleIcon',
//             variant: (status === 401 && 'warning') || 'danger',
//           },
//         })
//       }
//     }
//     return Promise.reject(error)
//   },
// )

Vue.prototype.$http = axiosIns

export default axiosIns
